import { EntityList } from '@lib/entity';
import { SdbSubscriptionInformationWeatherForecastEntity } from './sdb-subscription-information-weather-forecast-entity';

import type { Entity } from './sdb-subscription-information-weather-forecast-entity';

class SdbSubscriptionInformationWeatherForecastEntityList extends EntityList<SdbSubscriptionInformationWeatherForecastEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(SdbSubscriptionInformationWeatherForecastEntity, items, count);
    }
}

export { SdbSubscriptionInformationWeatherForecastEntityList };
