import { useSubscription } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { SdbSubscriptionInformationWeatherEntity } from '@modules/dashboard/entities';
import { OnSDBInformationWeatherUpdateSubscription } from '@modules/dashboard/graphql';

import type { OnSdbInformationWeatherUpdateSubscriptionType } from '@modules/types/graphql';

const useSdbInformationWeather = () => {
    const subscriptionResult = useSubscription<OnSdbInformationWeatherUpdateSubscriptionType>(
        OnSDBInformationWeatherUpdateSubscription,
    );

    const sdbInformationWeather = useEntity(
        () =>
            new SdbSubscriptionInformationWeatherEntity(
                subscriptionResult.data?.onSDBInformationWeatherUpdate ?? null,
            ),
        [subscriptionResult.data],
    );

    const result = {
        sdbInformationWeather,
        ...subscriptionResult,
    };

    return result;
};

export { useSdbInformationWeather };
