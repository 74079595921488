import React from 'react';
import styled  from 'styled-components';
import { red } from '@avangard/ui/colors';

import { CommonHelper } from '@helpers';

type BestStudentProps = {
    fullName?: string | null;
    company?: number | null;
    platoonX?: number | null;
    score?: number | null;
}

const Root = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`

const Name = styled.div`
  line-height: 1.2;
  padding-right: 10%;
`

const About = styled.div`
  flex: 0 0 auto;
  width: 150px;
  margin-left: auto;
  margin-right: 40px;
  display: flex;
  flex-direction: column;

  & > * {
    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }
`
const Result = styled.div`
  flex: 0 0 auto;
  width: 70px;
  text-align: center;
  margin-right: 40px;
  margin-top: -6px;
  line-height: 1;
`

const Count = styled.div`
  font-size: 2em;
  color: ${red[100]};
`

const Label = styled.div`
  margin-top: 4px;
  font-size: 0.6em;
`

const BestStudent = (props: BestStudentProps): React.ReactElement => {
    const { fullName, company, platoonX, score = 0 } = props;

    const displayScore = CommonHelper.createCountFormatter(score ?? 0, ['балл', 'балла', 'баллов'], false)

    return (
        <Root>
            <Name>
                {fullName}
            </Name>
            <About>
                <p>Рота: {company}</p>
                <p>Взвод: {platoonX}</p>
            </About>
            <Result>
                <Count>{score}</Count>
                <Label>{displayScore}</Label>
            </Result>
        </Root>
    );
};

export { BestStudent };
