import React from 'react';
import styled from 'styled-components';
import { lightBlueGray } from '@modules/ui/core/colors';

type CompaniesRatingItemBarProps = {
    score?: number;
    maxScore?: number;
};

const Root = styled.div`
    display: flex;
    align-items: center;

    & > * {
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
`;

const Line = styled.div`
    height: 36px;
    width: 18px;
    flex: 0 0 auto;
    display: block;
    background: rgba(135, 177, 211, 0.72);
    border: 1px solid ${lightBlueGray[60]};
    transform: skew(-15deg, 0deg);
`;

const CompaniesRatingItemBar = React.memo(
    (props: CompaniesRatingItemBarProps): React.ReactElement => {
        const { score = 0, maxScore = 0 } = props;

        const percent = maxScore > 0 ? (score * 100) / maxScore : 0;

        const maxNumberLines = 18;
        const currentNumberLines = ((maxNumberLines * percent) / 100).toFixed(0) ?? 0;
        const arrayForRender = new Array(+currentNumberLines).fill(0);

        return (
            <Root>
                {arrayForRender.map((_, index) => (
                    <Line key={index} />
                ))}
            </Root>
        );
    },
);

export { CompaniesRatingItemBar };
