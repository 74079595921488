import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { SdbGenderStudent } from '@modules/types/graphql';

export type Entity = Partial<SdbGenderStudent> | null;

@DecribeEntity('SdbGenderStudentEntity')
class SdbGenderStudentEntity extends BaseEntity {
    gender: string;
    age: number;
    count: number;

    constructor(entity: Entity) {
        super(entity);

        this.gender = entity?.gender ?? '';
        this.age = entity?.age ?? 0;
        this.count = entity?.count ?? 0;
    }
}

export { SdbGenderStudentEntity };
