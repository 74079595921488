import React from 'react';
import styled from 'styled-components';
import { red } from '@avangard/ui/colors';

import { lightBlueGray } from '@modules/ui/core/colors';
import { getDayOfWeek, getNextDayOfWeek } from '@modules/dashboard/helpers';
import { WeatherIcon } from '@modules/dashboard/atoms';

import type {
    SdbInformationDateEntity,
    SdbSubscriptionInformationWeatherForecastEntity,
} from '@modules/dashboard/entities';

type DashboardWeatherDetailsProps = {
    variant: 'today' | 'tomorrow';
    currentDate: SdbInformationDateEntity;
    forecast: SdbSubscriptionInformationWeatherForecastEntity;
};

const Root = styled.div`
    width: 100%;
    text-align: center;
`;

const Title = styled.div`
    color: ${red[100]};
    font-size: 2.5rem;
    text-transform: uppercase;
`;

const Subtitle = styled.div`
    color: ${lightBlueGray[50]};
    font-size: 8rem;
    line-height: 100px;
    text-transform: uppercase;
`;

const Main = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 56px;

    > * {
        width: calc(50% - 24px);
    }
`;

const Column = styled.div`
    > h4 {
        color: ${lightBlueGray[50]};
        font-size: 5.6rem;
        line-height: 60px;
    }
`;

const Icon = styled.div`
    width: 230px;
    height: 230px;
    margin: 0 auto;

    > img {
        max-width: 100%;
    }
`;

const Temperature = styled.div`
    margin-top: 16px;
    color: #fff;
    font-size: 15rem;
    line-height: 130px;
`;

const Hint = styled.div`
    max-width: 60%;
    margin: 40px auto 0 auto;
    color: #fff;
    font-size: 2.8rem;
    line-height: 32px;
`;

const DashboardWeatherDetails = (props: DashboardWeatherDetailsProps) => {
    const { variant, currentDate, forecast } = props;

    const { title, day } = React.useMemo(() => {
        if (variant === 'today') {
            return {
                title: 'Сегодня',
                day: getDayOfWeek(currentDate, 'dd MMMM'),
            };
        }

        return {
            title: 'Завтра',
            day: getNextDayOfWeek(currentDate, 'dd MMMM'),
        };
    }, [variant, currentDate]);

    return (
        <Root>
            <Title>{title}</Title>
            <Subtitle>{day}</Subtitle>

            <Main>
                <Column>
                    <h4>Днем</h4>

                    <Icon>
                        <WeatherIcon slug={forecast.day.conditionSlug} />
                    </Icon>

                    <Temperature>{forecast.day.temp}</Temperature>
                    <Hint>{forecast.day.condition}</Hint>
                </Column>

                <Column>
                    <h4>Ночью</h4>

                    <Icon>
                        <WeatherIcon slug={forecast.night.conditionSlug} />
                    </Icon>

                    <Temperature>{forecast.night.temp}</Temperature>
                    <Hint>{forecast.night.condition}</Hint>
                </Column>
            </Main>
        </Root>
    );
};

export { DashboardWeatherDetails };
