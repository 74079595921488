import React from 'react';
import styled from 'styled-components';

import { autoScroll } from '@lib/auto-scroll';

import type { AutoScroll } from '@lib/auto-scroll';

const Root = styled.div`
    font-size: 0.8em;
    height: 490px;
    margin: -30px 0;
    width: 100%;
    padding: 30px;
    overflow-x: hidden;
    overflow-y: auto;

    & > * {
        margin-bottom: 30px;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const Schedule = (): React.ReactElement => {
    const scheduleRef = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
        let autoscrollInstance: AutoScroll | null = null;

        if (scheduleRef.current) {
            autoscrollInstance = autoScroll.create(scheduleRef.current, { plane: 'vertical' });
            autoscrollInstance.startScroll(true);
        }

        return () => {
            if (autoscrollInstance) {
                autoscrollInstance.destroy();
            }
        };
    }, [scheduleRef]);

    return (
        <Root ref={scheduleRef}>
            <p>7:00 Подъем</p>
            <p>7:10 - 7:30 Утренние мероприятия</p>
            <p>7:30 - 7:50 Утренний туалет, заправка постелей, уборка в жилых помещениях</p>
            <p>7:50 - 8:00 Утренний осмотр, выход на завтрак</p>
            <p>8:00 - 8:25 Завтрак (1 группа: 1-13 взводы)</p>
            <p>8:25 - 8:50 Завтрак (2 группа: 14-25 взводы)</p>
            <p>9:00 - 12:30 Учебные занятия</p>
            <p>12:30 - 13:00 Работа вожатых со взводами, подготовка к обеду</p>
            <p>13:00 - 13:25 Обед (1 группа: 1-13 взводы)</p>
            <p>13:25 - 13:50 Обед (2 группа: 14-25 взводы)</p>
            <p>14:00 - 16:35 Учебные занятия</p>
            <p>16:35 - 17:00 Полдник</p>
            <p>17:00 - 19:00 Вечерние мероприятия</p>
            <p>19:00 - 19:25 Ужин (1 группа: 1-13 взводы)</p>
            <p>19:25 - 19:50 Ужин (2 группа: 14-25 взводы)</p>
            <p>20:00 - 21:00 Вечерние мероприятия</p>
            <p>
                21:00 - 22:00 Сбор взводов в жилых корпусах, подведение итогов дня, второй ужин,
                подготовка к отбою
            </p>
            <p>22:00 Отбой</p>
        </Root>
    );
};

export { Schedule };
