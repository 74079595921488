import { useSubscription } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { StandRatingsStudentItemEntityList } from '@modules/dashboard/entities';
import { OnStandTopStudentsRatingsUpdateSubscription } from '@modules/dashboard/graphql';

import type { OnStandTopStudentsRatingsUpdateSubscriptionType } from '@modules/types/graphql';

const useTopStudentsRatings = () => {
    const subscriptionResult = useSubscription<OnStandTopStudentsRatingsUpdateSubscriptionType>(OnStandTopStudentsRatingsUpdateSubscription);

    const { entities: topStudentsRatings, count } = useEntity(
        () =>
            new StandRatingsStudentItemEntityList(
                subscriptionResult.data?.onStandTopStudentsRatingsUpdate ?? null,
                subscriptionResult.data?.onStandTopStudentsRatingsUpdate?.length,
            ),
        [subscriptionResult.data],
    );

    const result = {
        topStudentsRatings,
        topStudentsRatingsExists: count !== 0,
        count,
        ...subscriptionResult,
    };

    return result;
};

export { useTopStudentsRatings };
