import React from 'react';

type UseThrottleScrollOptions = {
    cardsCount: number;
    minCardsCount: number;
    intervalDelay?: number;
};

const scrollEndLimit = 1880;

const useThrottleScroll = (options: UseThrottleScrollOptions) => {
    const { cardsCount, minCardsCount, intervalDelay } = options;

    const elementRef = React.useRef<HTMLDivElement>(null);
    const currentTickRef = React.useRef<number>(1);

    React.useEffect(() => {
        let timeoutId: NodeJS.Timeout | null = null;
        let intervalId: NodeJS.Timeout | null = null;

        if (!elementRef.current || cardsCount <= minCardsCount) {
            return;
        }

        const run = (tickCount: number) => {
            timeoutId = setTimeout(() => {
                intervalId = setInterval(() => {
                    if (!elementRef.current) {
                        return;
                    }

                    elementRef.current.scrollLeft += 10;

                    const formattedScrollLeft = Math.ceil(elementRef.current.scrollLeft);

                    if (intervalId) {
                        if (
                            currentTickRef.current + 1 === tickCount &&
                            (scrollEndLimit + 60) * currentTickRef.current - formattedScrollLeft <
                                600
                        ) {
                            clearInterval(intervalId);

                            elementRef.current.scrollLeft = elementRef.current.scrollWidth;

                            return;
                        }

                        if (formattedScrollLeft === scrollEndLimit * currentTickRef.current) {
                            clearInterval(intervalId);
                            run(tickCount);

                            currentTickRef.current += 1;
                        }
                    }
                }, 1000 / 60);
            }, intervalDelay);
        };

        const listCount = Math.ceil(cardsCount / minCardsCount);

        run(listCount);

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }

            if (intervalId) {
                clearInterval(intervalId);
            }

            if (elementRef.current) {
                elementRef.current.scrollLeft = 0;
            }

            currentTickRef.current = 1;
        };
    }, [cardsCount, minCardsCount, intervalDelay]);

    return { elementRef };
};

export { useThrottleScroll };
