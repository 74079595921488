import React from 'react';
import styled from 'styled-components';

import { CompaniesRatingItem, CompaniesRatingItemBar } from '@modules/dashboard/moleculas';

import type { CompaniesRatingItem as CompaniesRatingItemEntity } from '@modules/dashboard/entities';

type CompaniesRatingProps = {
    companies?: (CompaniesRatingItemEntity | null)[] | null;
    maxScore?: number;
};

const Root = styled.div`
    padding: 10px;

    & > * {
        &:not(:last-child) {
            margin-bottom: 40px;
        }
    }
`;

const CompaniesRating = (props: CompaniesRatingProps): React.ReactElement | null => {
    const { companies = [], maxScore = 0 } = props;

    if (!companies) {
        return null;
    }

    return (
        <Root>
            {companies.map((company, index) => (
                <CompaniesRatingItem key={index} company={company?.company} score={company?.score}>
                    <CompaniesRatingItemBar score={company?.score} maxScore={maxScore} />
                </CompaniesRatingItem>
            ))}
        </Root>
    );
};

export { CompaniesRating };
