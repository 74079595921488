import React from 'react';
import styled from 'styled-components';

import { autoScroll } from '@lib/auto-scroll';
import { StandCard } from '@modules/shared/organisms';
import { StandPageTitle } from '@modules/layout/moleculas';
import { DashboardEventsList } from '@modules/dashboard/organisms';

import type { AutoScroll } from '@lib/auto-scroll';
import type { EntryProps } from './types';

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    padding: 30px;
    overflow: hidden;
    background: black;
`;

const List = styled.div`
    width: 100%;
    height: 700px;
    overflow: auto;
`;

const EventsEntry = (props: EntryProps): React.ReactElement => {
    const { informationData, informationDate, onAddTransitionTimeout } = props;

    const eventsRef = React.useRef<HTMLDivElement>(null);
    const eventsCount = informationData.events.length;

    React.useEffect(() => {
        let autoscrollInstance: AutoScroll | null = null;

        if (eventsCount > 3 && eventsRef.current) {
            autoscrollInstance = autoScroll.create(eventsRef.current, {
                plane: 'vertical',
                speed: 0.8,
            });

            autoscrollInstance.startScroll(true);

            onAddTransitionTimeout((Math.ceil(eventsCount / 3) - 1) * 17000);
        }

        return () => {
            if (autoscrollInstance) {
                autoscrollInstance.destroy();
            }
        };
    }, [eventsCount]);

    return (
        <Root>
            <StandPageTitle
                title='События заезда'
                studentsCount={informationData.students}
                currentDate={informationDate}
            />

            <StandCard>
                <List ref={eventsRef}>
                    <DashboardEventsList events={informationData.events} />
                </List>
            </StandCard>
        </Root>
    );
};

export { EventsEntry };
