import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { SdbInformationDate } from '@modules/types/graphql';

export type Entity = SdbInformationDate | null;

@DecribeEntity('SdbInformationDateEntity')
class SdbInformationDateEntity extends BaseEntity {
    date: string;
    time: string;

    constructor(entity: Entity) {
        super(entity);

        this.date = entity?.date ?? ''
        this.time = entity?.time ?? ''
    }
}

export { SdbInformationDateEntity };
