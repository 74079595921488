import { WeatherConditionTypes } from '@modules/types/graphql';

type WeatherIconProps = {
    slug?: string
}

const imagesBySlugMap: Record<string, string> = {
    [WeatherConditionTypes.clear]: 'sun',
    [WeatherConditionTypes.cloudy]: 'cloud_sun',
    [WeatherConditionTypes.continuousHeavyRain]: 'rain',
    [WeatherConditionTypes.drizzle]: 'rain',
    [WeatherConditionTypes.hail]: 'hail',
    [WeatherConditionTypes.heavyRain]: 'rain',
    [WeatherConditionTypes.lightRain]: 'rain',
    [WeatherConditionTypes.lightSnow]: 'snow',
    [WeatherConditionTypes.moderateRain]: 'rain',
    [WeatherConditionTypes.overcast]: 'cloud',
    [WeatherConditionTypes.partyCloudy]: 'cloud',
    [WeatherConditionTypes.partlyCloudy]: 'cloud',
    [WeatherConditionTypes.rain]: 'rain',
    [WeatherConditionTypes.showers]: 'rain',
    [WeatherConditionTypes.snow]: 'snow',
    [WeatherConditionTypes.snowShowers]: 'snow',
    [WeatherConditionTypes.thunderstorm]: 'storm',
    [WeatherConditionTypes.thunderstormWithHail]: 'storm',
    [WeatherConditionTypes.thunderstormWithRain]: 'storm',
    [WeatherConditionTypes.wetSnow]: 'snow',
}

const WeatherIcon = (props: WeatherIconProps): React.ReactElement | null => {
    const { slug } = props;

    if (!slug) {
        return null;
    }

    const imagePath = imagesBySlugMap[slug];

    if (!imagePath) {
        return null;
    }

    return (
        <img src={`/images/weather/${imagePath}.gif`} alt={slug} />
    )
};

export { WeatherIcon };
