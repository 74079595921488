import { gql } from '@apollo/client';

export const OnStandTopStudentsRatingsUpdateSubscription = gql`
    subscription OnStandTopStudentsRatingsUpdate {
        onStandTopStudentsRatingsUpdate {
            id
            fullName
            company
            platoonX
            score
        }
    }
`;
