import React from 'react';
import styled from 'styled-components';

import { StandCard } from '@modules/shared/organisms';
import { SdbInformationDateEntity, Lap } from '@modules/dashboard/entities';
import { Logo } from '@modules/dashboard/moleculas';
import { CurrentLap } from '@modules/dashboard/organisms';

type DashboardTopProps = {
    students: number,
    date: SdbInformationDateEntity,
    lap: Lap | null
}

const Root = styled.div`
  display: flex;
  align-items: center;
`;

const TopLogo = styled.div`
  margin-right: -40px;
`;

const DashboardTop = (props: DashboardTopProps): React.ReactElement => {
    const { students, date, lap } = props;

    return (
        <Root>
            <StandCard empty={!students || !date || !lap}>
                <CurrentLap
                    students={students}
                    date={date}
                    lap={lap}
                />
            </StandCard>

          {/*<TopLogo>*/}
          {/*  <Logo />*/}
          {/*</TopLogo>*/}
        </Root>
    );
};

export { DashboardTop };
