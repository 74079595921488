import styled from 'styled-components';
import { range } from 'lodash';
import { red } from '@avangard/ui/colors';

import { lightBlueGray } from '@modules/ui/core/colors';

import type { PlatoonsRatingItem } from '@modules/dashboard/entities';

type DashboardPlatoonRatingProps = {
    from: number;
    to: number;
    ratings: (PlatoonsRatingItem | null)[];
};

const Root = styled.div`
    width: 100%;
`;

const Title = styled.div`
    color: ${lightBlueGray[50]};
    font-size: 2.5rem;
    text-transform: uppercase;
`;

const Main = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 32px;

    > * {
        width: calc(100% / 3 - 24px);
    }
`;

const Column = styled.div`
    > h4 {
        font-size: 1.6rem;
        line-height: 18px;
        text-transform: uppercase;
    }
`;

const List = styled.div<{ color?: string }>`
    margin-top: 24px;

    > p {
        color: ${p => (p.color ? p.color : '#fff')};
        margin-bottom: 8px;
        font-size: 5.6rem;
        line-height: 52px;
    }
`;

const DashboardPlatoonRating = (props: DashboardPlatoonRatingProps) => {
    const { from, to, ratings } = props;

    const places = range(from, to + 1);

    return (
        <Root>
            <Title>
                {from} - {to} место
            </Title>

            <Main>
                <Column>
                    <h4>Место</h4>

                    <List>
                        {places.map((place, idx) =>
                            ratings[idx] ? <p key={place}>{place}</p> : null,
                        )}
                    </List>
                </Column>

                <Column>
                    <h4>Взвод</h4>

                    <List color={lightBlueGray[50]}>
                        {ratings.map(rating =>
                            rating ? <p key={rating.platoonX}>{rating.platoonX}</p> : null,
                        )}
                    </List>
                </Column>

                <Column>
                    <h4>Баллов</h4>

                    <List color={red[100]}>
                        {ratings.map(rating =>
                            rating ? (
                                <p key={rating.platoonX + '#' + rating.score}>{rating.score}</p>
                            ) : null,
                        )}
                    </List>
                </Column>
            </Main>
        </Root>
    );
};

export { DashboardPlatoonRating };
