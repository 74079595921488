import styled, { css } from 'styled-components';

import { Rectangle } from './rectangle';

type GenderStudentsRatioProps = {
    malePercent: number;
    femalePercent: number;
};

const Root = styled.div`
    width: 100%;

    & > *:first-child {
        margin-top: 130px;
        z-index: 10;
    }

    & > *:last-child {
        z-index: 1;
    }
`;

const GenderStudentsRatio = (props: GenderStudentsRatioProps) => {
    const { malePercent, femalePercent } = props;

    return (
        <Root>
            {malePercent > 0 ? <Rectangle variant='primary' percent={malePercent} /> : null}
            {femalePercent > 0 ? <Rectangle variant='secondary' percent={femalePercent} /> : null}
        </Root>
    );
};

export { GenderStudentsRatio };
