import styled from 'styled-components';

import { lightBlueGray } from '@modules/ui/core/colors';
import { DashboardEventsListItem } from './dashboard-events-list-item';

import { SdbEventEntity } from '@modules/dashboard/entities';

type DashboardEventsListProps = {
    events: SdbEventEntity[];
};

const Root = styled.div`
    width: 100%;
    text-align: center;

    > *:not(:last-child) {
        border-bottom: 1px solid ${lightBlueGray[80]};
    }
`;

const DashboardEventsList = (props: DashboardEventsListProps) => {
    const { events } = props;

    return (
        <Root>
            {events.map(event => (
                <DashboardEventsListItem key={event.id} event={event} />
            ))}
        </Root>
    );
};

export { DashboardEventsList };
