import React from 'react';
import styled from 'styled-components';

import { Gender } from '@modules/types/graphql';
import { StandCard } from '@modules/shared/organisms';
import { StandPageTitle } from '@modules/layout/moleculas';
import { GenderStudentsList, GenderStudentsRatio } from '@modules/dashboard/organisms';

import type { EntryProps } from './types';

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    padding: 30px;
    overflow: hidden;
    background: black;
`;

const GenderStudentsStandCard = styled(StandCard)`
    > .stand-card-content {
        padding: 44px 34px;
    }
`;

const GenderStudents = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 84px;
    justify-content: space-between;
    width: 100%;
`;

const GenderStudentsEntry = (props: EntryProps): React.ReactElement => {
    const { informationData, informationDate, onAddTransitionTimeout } = props;

    const maleStudentsCollection = informationData.getStudentsCollectionByGender(Gender.male);
    const maleStudentsPercent = informationData.getGenderStudentsPercent(Gender.male);

    const femaleStudentsCollection = informationData.getStudentsCollectionByGender(Gender.female);
    const femaleStudentsPercent = informationData.getGenderStudentsPercent(Gender.female);

    const greaterGenderItems = informationData.getGreaterStudentsCollection();

    React.useEffect(() => {
        if (greaterGenderItems > 6) {
            onAddTransitionTimeout(Math.ceil(greaterGenderItems / 6) * 3000);
        }
    }, [greaterGenderItems]);

    return (
        <Root>
            <StandPageTitle
                title='Пол и возраст курсантов'
                studentsCount={informationData.students}
                currentDate={informationDate}
            />

            <GenderStudentsStandCard>
                <GenderStudents>
                    <GenderStudentsList
                        gender={Gender.male}
                        percent={maleStudentsPercent}
                        studentsCollection={maleStudentsCollection}
                    />

                    <GenderStudentsRatio
                        malePercent={maleStudentsPercent}
                        femalePercent={femaleStudentsPercent}
                    />

                    <GenderStudentsList
                        gender={Gender.female}
                        percent={femaleStudentsPercent}
                        studentsCollection={femaleStudentsCollection}
                    />
                </GenderStudents>
            </GenderStudentsStandCard>
        </Root>
    );
};

export { GenderStudentsEntry };
