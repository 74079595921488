import { BaseEntity, Entity as DecribeEntity, RelationList } from '@lib/entity';

import { SdbGenderStudentEntity } from './sdb-gender-student-entity';
import { SdbGenderStudentEntityList } from './sdb-gender-student-entity-list';

import type { SdbGenderStudents } from '@modules/types/graphql';

export type Entity = Partial<SdbGenderStudents> | null;

@DecribeEntity('SdbGenderStudentsEntity')
class SdbGenderStudentsEntity extends BaseEntity {
    gender: string;
    summary: number;

    @RelationList(() => SdbGenderStudentEntityList)
    items: SdbGenderStudentEntity[];

    constructor(entity: Entity) {
        super(entity);

        this.gender = entity?.gender ?? '';
        this.summary = entity?.summary ?? 0;
    }
}

export { SdbGenderStudentsEntity };
