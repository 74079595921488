import React from 'react';
import styled from 'styled-components';
import { red } from '@avangard/ui/colors';

import { CommonHelper } from '@helpers';
import { darkBlue } from '@modules/ui/core/colors';

type CompaniesRatingItemProps = {
    company?: number;
    score?: number;
    children?: React.ReactElement;
};

const Root = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    font-size: 0.8em;

    &:nth-child(odd):before {
        content: '';
        position: absolute;
        left: -60px;
        top: 50%;
        transform: translateY(-50%);
        background: ${darkBlue[50]};
        opacity: 0.1;
        display: block;
        width: 100vw;
        height: 66px;
        pointer-events: none;
    }
`;

const Company = styled.div`
    margin-right: 20px;
    width: 95px;
`;

const Score = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-width: 210px;

    span {
        margin-right: 8px;
        color: ${red[100]};
    }
`;

const CompaniesRatingItem = (props: CompaniesRatingItemProps): React.ReactElement => {
    const { company = 0, score = 0, children } = props;

    const displayScore = CommonHelper.createCountFormatter(
        score,
        ['балл', 'балла', 'баллов'],
        false,
    );

    return (
        <Root>
            <Company>{company} рота</Company>

            <Score>
                <span>{score}</span> {displayScore}
            </Score>

            {children}
        </Root>
    );
};

export { CompaniesRatingItem };
