import { EntityList } from '@lib/entity';

import { SdbInformationBirthdateStudentEntity } from './sdb-information-birthdate-student-entity';

import type { Entity } from './sdb-information-birthdate-student-entity';

class SdbInformationBirthdateStudentEntityList extends EntityList<SdbInformationBirthdateStudentEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(SdbInformationBirthdateStudentEntity, items, count);
    }
}

export { SdbInformationBirthdateStudentEntityList };
