import React from 'react';
import styled  from 'styled-components';

import { StandRow } from '@modules/shared/organisms';
import { BestStudentPlace } from '@modules/dashboard/atoms';
import { BestStudent } from '@modules/dashboard/organisms';

import type { StandRatingsStudentItemEntity } from '@modules/dashboard/entities';

type DashboardBestStudentsProps = {
    students?: (StandRatingsStudentItemEntity | null)[] | null;
}

const Root = styled.div`
  display: block;
  
  & > * {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
`

const DashboardBestStudents = (props: DashboardBestStudentsProps): React.ReactElement => {
    const { students } = props;

    return (
        <Root>
            {students?.map((student, index) => (
                <StandRow key={index} empty={!student?.id} icon={<BestStudentPlace place={index + 1} />}>
                    <BestStudent
                        fullName={student?.fullName}
                        company={student?.company}
                        platoonX={student?.platoonX}
                        score={student?.score}
                    />
                </StandRow>
            ))}
        </Root>
    );
};

export { DashboardBestStudents };
