import React from 'react';
import styled from 'styled-components';

import { CommonHelper } from '@helpers';
import { getDayOfWeek } from '@modules/dashboard/helpers';
import { getFormattedLap } from './helpers';

import type { Lap, SdbInformationDateEntity } from '@modules/dashboard/entities';

type CurrentLapProps = {
    students?: number;
    date?: SdbInformationDateEntity | null;
    lap?: Lap | null;
};

const Root = styled.div`
    display: block;
`;

const About = styled.div`
    margin-left: 10px;
    margin-bottom: 20px;
`;

const Date = styled.div`
    display: flex;
    align-items: flex-start;
`;

const Time = styled.div`
    margin-right: 44px;
    line-height: 130px;
    font-size: 4.4em;
`;

const Day = styled.div`
    margin-top: 20px;
`;

const CurrentLap = (props: CurrentLapProps): React.ReactElement => {
    const { students = 0, date, lap } = props;

    const displayStudents = CommonHelper.createCountFormatter(students, [
        'курсант',
        'курсанта',
        'курсантов',
    ]);
    const displayLapRange = getFormattedLap(lap);
    const displayAbout = `${displayStudents}, ${displayLapRange}`;

    const formattedDayOfWeek = getDayOfWeek(date);
    const formattedNumberDayOfWeek = getDayOfWeek(date, 'dd MMMM');

    return (
        <Root>
            <About>
                <p>{displayAbout}</p>
            </About>

            <Date>
                <Time>{date?.time}</Time>
                <Day>
                    <p>{formattedDayOfWeek}</p>
                    <p>{formattedNumberDayOfWeek}</p>
                </Day>
            </Date>
        </Root>
    );
};

export { CurrentLap };
