import { BaseEntity, Entity as DecribeEntity, Relation } from '@lib/entity';

import { SdbInformationWeatherEntity } from './sdb-information-weather-entity';

import type { SdbSubscriptionInformationWeatherForecast } from '@modules/types/graphql';

export type Entity = SdbSubscriptionInformationWeatherForecast | null;

@DecribeEntity('SdbSubscriptionInformationWeatherForecastEntity')
class SdbSubscriptionInformationWeatherForecastEntity extends BaseEntity {
    date: string;

    @Relation(() => SdbInformationWeatherEntity)
    day: SdbInformationWeatherEntity;

    @Relation(() => SdbInformationWeatherEntity)
    night: SdbInformationWeatherEntity;

    constructor(entity: Entity) {
        super(entity);

        this.date = entity?.date ?? '';
    }
}

export { SdbSubscriptionInformationWeatherForecastEntity };
