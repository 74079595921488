import { useSubscription } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { SdbInformationDateEntity } from '@modules/dashboard/entities';
import { OnSDBInformationDateUpdateSubscription } from '@modules/dashboard/graphql';

import type { OnSdbInformationDateUpdateSubscriptionType } from '@modules/types/graphql';

const useSdbInformationDate = () => {
    const subscriptionResult = useSubscription<OnSdbInformationDateUpdateSubscriptionType>(OnSDBInformationDateUpdateSubscription);

    const sdbInformationDate = useEntity(
        () => new SdbInformationDateEntity(subscriptionResult.data?.onSDBInformationDateUpdate ?? null),
        [subscriptionResult.data],
    );

    const result = {
        sdbInformationDate,
        ...subscriptionResult,
    };

    return result;
};

export { useSdbInformationDate };
