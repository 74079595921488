import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { SdbInformationWeather } from '@modules/types/graphql';

export type Entity = SdbInformationWeather | null;

@DecribeEntity('SdbInformationWeatherEntity')
class SdbInformationWeatherEntity extends BaseEntity {
    temp: number;
    icon: string;
    conditionSlug: string;
    condition: string;

    constructor(entity: Entity) {
        super(entity);

        this.temp = entity?.temp ?? 0;
        this.icon = entity?.icon ?? '';
        this.conditionSlug = entity?.conditionSlug ?? '';
        this.condition = entity?.condition ?? '';
    }
}

export { SdbInformationWeatherEntity };
