import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

import type { SdbInformationDateEntity } from '@modules/dashboard/entities';

const getDayOfWeek = (date?: SdbInformationDateEntity | null, formatString = 'EEEE') => {
    if (!date) {
        return '';
    }

    try {
        const currentDate = new Date(date.date);
        const dayOfWeek = format(currentDate, formatString, { locale: ru });

        return dayOfWeek;
    } catch {
        return '';
    }
};

export { getDayOfWeek };
