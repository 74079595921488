import React from 'react';
import styled, { css } from 'styled-components';

import { StandCard } from '@modules/shared/organisms';
import { StandPageTitle } from '@modules/layout/moleculas';
import { useThrottleScroll } from '@modules/dashboard/hooks';
import { DashboardStudentBirthdayDetails } from '@modules/dashboard/organisms';

import type { EntryProps } from './types';

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    padding: 30px;
    overflow: hidden;
    background: black;
`;

const List = styled.div<{ center: boolean }>`
    display: flex;
    gap: 28px;
    width: 100%;
    overflow: auto;

    > * {
        display: inline-flex;
        min-width: 600px;
        max-width: 600px;
    }

    ${p =>
        p.center &&
        css`
            justify-content: center;
        `}
`;

const StudentsBirthdayEntry = (props: EntryProps): React.ReactElement => {
    const { informationData, informationDate, onAddTransitionTimeout } = props;

    const cardsCount = informationData.birthdateStudents.length;

    const { elementRef } = useThrottleScroll({
        cardsCount,
        minCardsCount: 3,
        intervalDelay: 10000,
    });

    React.useEffect(() => {
        const listCount = Math.ceil(cardsCount / 3);

        onAddTransitionTimeout((listCount - 1) * 13000);
    }, [cardsCount]);

    const greateThatTwoCards = cardsCount <= 2;

    return (
        <Root>
            <StandPageTitle
                title='Поздравляем с Днем Рождения!'
                studentsCount={informationData.students}
                currentDate={informationDate}
            />

            <List ref={elementRef} center={greateThatTwoCards}>
                {informationData.birthdateStudents.map(student => (
                    <StandCard key={student.id}>
                        <DashboardStudentBirthdayDetails birthdateStudent={student} />
                    </StandCard>
                ))}
            </List>
        </Root>
    );
};

export { StudentsBirthdayEntry };
