import styled from 'styled-components';

import { lightBlueGray } from '@modules/ui/core/colors';

import { SdbEventEntity } from '@modules/dashboard/entities';

type DashboardEventsListItemProps = {
    event: SdbEventEntity;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 42px 0;
`;

const Date = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 345px;
    color: ${lightBlueGray[50]};

    > h4 {
        font-size: 10rem;
        line-height: 72px;
    }
`;

const DateDetails = styled.div`
    margin-left: 16px;
    color: ${lightBlueGray[50]};
    font-size: 3.2rem;
    line-height: 40px;
    text-transform: uppercase;
    text-align: left;
`;

const Frame = styled.div`
    width: 260px;
    margin-left: 32px;
    color: ${lightBlueGray[50]};
    font-size: 3.2rem;
    line-height: 40px;
    text-align: left;
    text-transform: uppercase;
    word-break: break-word;
`;

const Title = styled.div`
    max-width: 900px;
    margin-left: 40px;
    color: ${lightBlueGray[50]};
    font-size: 6.8rem;
    line-height: 72px;
    text-align: left;
    text-transform: uppercase;
`;

const Image = styled.div`
    width: 144px;
    height: 144px;
    margin-left: auto;

    > img {
        max-width: 100%;
    }
`;

const DashboardEventsListItem = (props: DashboardEventsListItemProps) => {
    const { event } = props;

    return (
        <Root>
            <Date>
                <h4>{event.getDay()}</h4>

                <DateDetails>
                    <p>{event.getMonth()}</p>
                    <p>{event.getDayOfWeek()}</p>
                </DateDetails>
            </Date>

            <Frame>
                <p>{event.placeName}</p>
                <p>{event.time}</p>
            </Frame>

            <Title>{event.name}</Title>

            {event.photo.exists() ? (
                <Image>
                    <img src={event.photo.origin} alt={event.name} />
                </Image>
            ) : null}
        </Root>
    );
};

export { DashboardEventsListItem };
