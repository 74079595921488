import React from 'react';
import styled, { css } from 'styled-components';
import { black, red } from '@avangard/ui/colors';

import { lightBlueGray } from '@modules/ui/core/colors';

type DashboardCardProps = {
    children?: React.ReactElement | React.ReactElement[];
    withoutBg?: boolean;
    empty?: boolean;
    className?: string;
};

const Root = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    border: 2px solid ${lightBlueGray[80]};
    width: 100%;
    border-radius: 2px;
    letter-spacing: 0.4px;
    box-shadow: 0 0 5px 3px rgba(183, 215, 231, 0.3), inset 0 0 5px 3px rgba(183, 215, 231, 0.3);
    text-transform: uppercase;
    font-size: 3.4em;
    color: ${lightBlueGray[50]};
`;

const Line = styled.div`
    display: block;
    content: '';
    height: 34px;
    width: 100%;
    background: linear-gradient(
        180deg,
        rgba(88, 98, 108, 1) 0%,
        rgba(47, 56, 65, 1) 58%,
        rgb(29, 36, 43) 100%
    );
    position: relative;
    border-bottom: 2px solid ${lightBlueGray[100]};

    &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 2px;
        background: ${red[100]};
        box-shadow: 0 0 1px 2px ${black[10]};
        width: 100%;
        height: 6px;
    }
`;

const Content = styled.div<{ withoutBg?: boolean }>`
    padding: 34px;
    height: 100%;
    position: relative;
    overflow: hidden;

    ${p =>
        !p.withoutBg &&
        css` & {
    background-image: url('images/dashboard-card-bg.png');
  `};

    ${p =>
        !!p.withoutBg &&
        css`
            & {
                & > * {
                    z-index: 1;
                    position: relative;
                }

                &:before {
                    content: '';
                    display: block;
                    z-index: 0;
                    pointer-events: none;
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    background-color: ${lightBlueGray[90]};
                    opacity: 0.4;
                }
            }
        `};

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`;

const Crop = styled.div`
    content: '';
    display: block;
    pointer-events: none;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
`;

const CropItem = styled.div`
    position: absolute;
    z-index: 1;
    height: 20px;
    width: 20px;
    opacity: 0.5;

    &:nth-child(1) {
        border-left: 2px solid ${lightBlueGray[80]};
        border-top: 2px solid ${lightBlueGray[80]};
        left: 8px;
        top: 8px;
    }

    &:nth-child(2) {
        border-right: 2px solid ${lightBlueGray[80]};
        border-top: 2px solid ${lightBlueGray[80]};
        right: 8px;
        top: 8px;
    }

    &:nth-child(3) {
        border-right: 2px solid ${lightBlueGray[80]};
        border-bottom: 2px solid ${lightBlueGray[80]};
        right: 8px;
        bottom: 8px;
    }

    &:nth-child(4) {
        border-left: 2px solid ${lightBlueGray[80]};
        border-bottom: 2px solid ${lightBlueGray[80]};
        left: 8px;
        bottom: 8px;
    }
`;

const Empty = styled.div`
    font-size: 1.4em;
    text-align: center;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StandCard = React.forwardRef<HTMLDivElement, DashboardCardProps>((props, ref) => {
    const { children, empty, withoutBg, className } = props;

    return (
        <Root ref={ref} className={className}>
            <Line />

            <Content className='stand-card-content' withoutBg={withoutBg}>
                <Crop>
                    <CropItem />
                    <CropItem />
                    <CropItem />
                    <CropItem />
                </Crop>

                {empty ? <Empty>Нет данных</Empty> : children}
            </Content>
        </Root>
    );
});

export { StandCard };
