import styled from 'styled-components';
import { white } from '@avangard/ui/colors';

type BestStudentPlaceProps = {
    place?: number
}

const Root = styled.div`
  font-weight: 900;
  font-style: italic;
  font-size: 10em;
  font-family: "Arial Black", serif;
  color: ${white[100]};
  letter-spacing: 20px;
`

const BestStudentPlace = (props: BestStudentPlaceProps): React.ReactElement | null => {
    const { place } = props;

    if (!place) {
        return null;
    }

    return (
        <Root>
            {place}
        </Root>
    )
};

export { BestStudentPlace };
