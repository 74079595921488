import React from 'react';
import styled, { css } from 'styled-components';

import { autoScroll } from '@lib/auto-scroll';
import { lightBlueGray, red } from '@modules/ui/core/colors';
import { Gender } from '@modules/types/graphql';
import { GenderStudentsListItem } from './gender-students-list-item';

import type { AutoScroll } from '@lib/auto-scroll';
import type { SdbGenderStudentsEntity } from '@modules/dashboard/entities';

type GenderStudentsListProps = {
    gender: Gender;
    percent: number;
    studentsCollection?: SdbGenderStudentsEntity;
};

const Root = styled.div<{ variant: 'primary' | 'secondary' }>`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;

    ${p =>
        (p.variant === 'primary' &&
            css`
                color: ${lightBlueGray[50]};
            `) ||
        (p.variant === 'secondary' &&
            css`
                color: ${red[50]};
            `)}
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    > p {
        width: 150px;
        color: #fff;
        font-size: 3.8rem;
    }

    > h2 {
        margin-left: 16px;
        font-size: 6.8rem;
    }
`;

const List = styled.div`
    height: 468px;
    margin-top: 56px;
    overflow: auto;

    > * {
        margin-bottom: 32px;
    }
`;

const Footer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 36px;
    font-size: 3.8rem;
    border-top: 1px solid #7491a3;

    > p {
        color: #fff;
    }
`;

const genderOptions = {
    [Gender.male]: {
        variant: 'primary',
        title: 'Юноши',
        summary: 'Всего юношей',
    },
    [Gender.female]: {
        variant: 'secondary',
        title: 'Девушки',
        summary: 'Всего девушек',
    },
};

const GenderStudentsList = (props: GenderStudentsListProps) => {
    const { gender, percent, studentsCollection } = props;

    const listRef = React.useRef<HTMLDivElement>(null);

    const students = studentsCollection?.items ?? [];
    const studentsCount = students.length;

    React.useEffect(() => {
        let autoscrollInstance: AutoScroll | null = null;

        if (studentsCount > 6 && listRef.current) {
            autoscrollInstance = autoScroll.create(listRef.current, { plane: 'vertical' });
            autoscrollInstance.startScroll(true);
        }

        return () => {
            if (autoscrollInstance) {
                autoscrollInstance.destroy();
            }
        };
    }, [studentsCount]);

    const { variant, title, summary } = genderOptions[gender];

    const maxCount = Math.max(...students.map(student => student.count));

    return (
        <Root variant={variant}>
            <Header>
                <p>{percent}%</p>
                <h2>{title}</h2>
            </Header>

            <List ref={listRef}>
                {students.map(student => (
                    <GenderStudentsListItem
                        key={student.age}
                        age={student.age}
                        count={student.count}
                        maxCount={maxCount}
                    />
                ))}
            </List>

            <Footer>
                <p>{summary}</p>
                <h3>{studentsCollection?.summary}</h3>
            </Footer>
        </Root>
    );
};

export { GenderStudentsList };
