import { gql } from '@apollo/client';

export const OnSDBInformationDateUpdateSubscription = gql`
    subscription OnSDBInformationDateUpdate {
        onSDBInformationDateUpdate {
            date
            time
        }
    }
`;
