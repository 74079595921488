import styled from 'styled-components';
import { red } from '@avangard/ui/colors';

import { CommonHelper } from '@helpers';
import { lightBlueGray } from '@modules/ui/core/colors';
import { getDayOfWeek } from '@modules/dashboard/helpers';

import type { SdbInformationDateEntity } from '@modules/dashboard/entities';

type StandPageTitleProps = {
    title: string;
    studentsCount: number;
    currentDate: SdbInformationDateEntity;
};

const Root = styled.div`
    width: calc(100% - 180px);
    margin: 0 auto 32px auto;
`;

const InfoBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 14px;
    color: ${lightBlueGray[50]};
    font-size: 2.5rem;

    > p {
        display: inline-flex;
        justify-content: center;
        flex: 1;
        text-transform: uppercase;

        &:first-child {
            justify-content: flex-start;
        }

        &:last-child {
            justify-content: flex-end;
        }
    }
`;

const TitleBlock = styled.div`
    width: 100%;
    position: relative;
    height: 125px;
    padding: 12px 0;
    text-align: center;
    background-color: ${lightBlueGray[90]};

    &:before,
    &:after {
        content: '';
        position: absolute;
        width: 0;
        top: -1px;
        border-top: 63px solid transparent;
        border-bottom: 63px solid transparent;
    }

    &:before {
        width: 0;
        left: auto;
        right: calc(100% - 1px);
        border-right: 50px solid ${lightBlueGray[90]};
    }

    &:after {
        left: calc(100% - 1px);
        right: auto;
        border-left: 50px solid ${lightBlueGray[90]};
    }
`;

const Title = styled.div`
    color: ${lightBlueGray[50]};
    font-size: 8rem;
    font-weight: 400;
    line-height: 100px;
    text-transform: uppercase;
`;

const Arrow = styled.div`
    position: absolute;

    &:before {
        content: '';
        position: absolute;
        display: inline-block;
        width: 80px;
        height: 80px;
        border-top: 5px solid ${red[100]};
        border-right: 5px solid ${red[100]};
        overflow: hidden;
    }
`;

const LeftArrow = styled(Arrow)`
    top: 22px;
    left: -58px;

    &:before {
        transform: rotate(232deg) skew(14deg);
    }
`;

const RightArrow = styled(Arrow)`
    top: 24px;
    right: 20px;

    &:before {
        transform: rotate(52deg) skew(14deg);
    }
`;

const StandPageTitle = (props: StandPageTitleProps) => {
    const { title, studentsCount, currentDate } = props;

    const displayStudents = CommonHelper.createCountFormatter(studentsCount, [
        'курсант',
        'курсанта',
        'курсантов',
    ]);

    const formattedDayOfWeek = getDayOfWeek(currentDate);
    const formattedNumberDayOfWeek = getDayOfWeek(currentDate, 'dd MMMM');

    return (
        <Root>
            <InfoBlock>
                <p>{displayStudents}</p>
                <p>{currentDate?.time}</p>
                <p>{`${formattedDayOfWeek} ${formattedNumberDayOfWeek}`}</p>
            </InfoBlock>

            <TitleBlock>
                <LeftArrow />
                <Title>{title}</Title>
                <RightArrow />
            </TitleBlock>
        </Root>
    );
};

export { StandPageTitle };
