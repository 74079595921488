export const lightBlueGray = {
    50: '#A4D7FF',
    60: '#87B1D3',
    80: '#7491A3',
    90: '#425668',
    100: '#4C535C',
};

export const red = {
    50: '#E0252A',
};

export const darkBlue = {
    50: '#0a0a8d',
};
