import { format, addDays } from 'date-fns';
import { ru } from 'date-fns/locale';

import type { SdbInformationDateEntity } from '@modules/dashboard/entities';

const getNextDayOfWeek = (date?: SdbInformationDateEntity | null, formatString = 'EEEE') => {
    if (!date) {
        return '';
    }

    try {
        const currentDate = new Date(date.date);
        const dayOfWeek = format(addDays(currentDate, 1), formatString, { locale: ru });

        return dayOfWeek;
    } catch {
        return '';
    }
};

export { getNextDayOfWeek };
