import React from 'react';
import styled, { css } from 'styled-components';

import { StandCard } from '@modules/shared/organisms';
import { StandPageTitle } from '@modules/layout/moleculas';
import { useThrottleScroll } from '@modules/dashboard/hooks';
import { DashboardPlatoonRating } from '@modules/dashboard/organisms';

import type { EntryProps } from './types';

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    padding: 30px;
    overflow: hidden;
    background: black;
`;

const List = styled.div<{ center: boolean }>`
    display: flex;
    gap: 32px;
    width: 100%;
    overflow: auto;

    > * {
        display: inline-flex;
        min-width: 590px;
        max-width: 590px;
    }

    ${p =>
        p.center &&
        css`
            justify-content: center;
        `}
`;

const PlatoonRatingsEntry = (props: EntryProps): React.ReactElement => {
    const { informationData, informationDate, onAddTransitionTimeout } = props;

    const platoonsRatingLength = informationData.platoonsRating.length;
    const columnsCount = Math.ceil(platoonsRatingLength / 10);

    const { elementRef } = useThrottleScroll({
        cardsCount: columnsCount,
        minCardsCount: 3,
        intervalDelay: 10000,
    });

    React.useEffect(() => {
        const listCount = Math.ceil(columnsCount / 3);

        onAddTransitionTimeout((listCount - 1) * 13000);
    }, [columnsCount]);

    const greateThatTwoColumns = columnsCount <= 2;

    return (
        <Root>
            <StandPageTitle
                title='Рейтинг взводов'
                studentsCount={informationData.students}
                currentDate={informationDate}
            />

            <List ref={elementRef} center={greateThatTwoColumns}>
                {Array.from({ length: columnsCount }).map((_, index) => {
                    const from = index * 10 + 1;
                    const to = (index + 1) * 10;

                    return (
                        <StandCard key={index}>
                            <DashboardPlatoonRating
                                from={from}
                                to={to}
                                ratings={informationData.takePlatoonsRating(index * 10, to)}
                            />
                        </StandCard>
                    );
                })}
            </List>
        </Root>
    );
};

export { PlatoonRatingsEntry };
