import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { SdbInformationBirthdateStudent } from '@modules/types/graphql';

export type Entity = SdbInformationBirthdateStudent | null;

@DecribeEntity('SdbInformationBirthdateStudentEntity')
class SdbInformationBirthdateStudentEntity extends BaseEntity {
    id: string;
    fullName: string;
    company: number;
    platoon: number;
    birthdate: string;
    age: number;

    constructor(entity: Entity) {
        super(entity);

        this.id = entity?.id ?? '';
        this.fullName = entity?.fullName ?? '';
        this.company = entity?.company ?? 0;
        this.platoon = entity?.platoon ?? 0;
        this.birthdate = entity?.birthdate ?? '';
        this.age = entity?.age ?? 0;
    }

    getFormattedBirthdate() {
        if (!this.birthdate) {
            return '-';
        }

        try {
            const date = format(new Date(this.birthdate), 'dd MMMM', { locale: ru });

            return date;
        } catch {
            return '-';
        }
    }
}

export { SdbInformationBirthdateStudentEntity };
