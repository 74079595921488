import React from 'react';
import styled, { css } from 'styled-components';
import { red } from '@avangard/ui/colors';

import { lightBlueGray } from '@modules/ui/core/colors';

type DashboardCardProps = {
    icon: React.ReactElement;
    children?: React.ReactElement;
    gradientBorder?: boolean;
    empty?: boolean;
};

const Root = styled.div`
    display: flex;
`;

const Icon = styled.div`
    z-index: 1;
    background-color: ${lightBlueGray[90]};
    width: 80px;
    flex: 0 0 auto;
    position: relative;
    height: 150px;
    margin: 0 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 140%;
        position: relative;
        z-index: 1;
    }

    &:before,
    &:after {
        content: '';
        position: absolute;
        width: 0;
        top: 0;
        border-top: 75px solid transparent;
        border-bottom: 75px solid transparent;
    }

    &:before {
        left: 100%;
        border-left: 50px solid ${lightBlueGray[90]};
    }

    &:after {
        left: auto;
        right: 100%;
        width: 0;
        border-right: 50px solid ${lightBlueGray[90]};
    }
`;

const Background = styled.div<{ gradientBorder?: boolean }>`
    position: absolute;
    z-index: -1;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 85%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    ${p =>
        !!p.gradientBorder
            ? css`
                  background-image: url('/images/dashboard-row-blue-bg.png');
              `
            : css`
                  background-image: url('/images/dashboard-card-bg.png');
              `}
`;

const Arrow = styled.div`
    position: absolute;
    left: 48px;
    width: 10px;
    flex: 0 0 auto;
    height: 180px;

    &:before {
        content: '';
        position: absolute;
        overflow: hidden;
        display: inline-block;
        width: 72px;
        height: 72px;
        top: 42px;
        border-top: 5px solid ${red[100]};
        border-right: 5px solid ${red[100]};
        transform: rotate(56deg) skew(24deg);
        right: 0;
    }
`;

const Content = styled.div<{ gradientBorder?: boolean }>`
    margin-left: -50px;
    padding-left: 80px;
    position: relative;
    z-index: 0;
    width: 100%;
    text-transform: uppercase;
    font-size: 3em;
    color: ${lightBlueGray[50]};

    ${p =>
        !p.gradientBorder &&
        css`
            border-right: 3px solid ${lightBlueGray[90]};
        `}

    &:after, &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        height: 3px;

        ${p =>
            !!p.gradientBorder
                ? css`
                      background-image: linear-gradient(
                          to right,
                          ${lightBlueGray[90]},
                          ${lightBlueGray[90]},
                          rgba(0, 0, 0, 0)
                      );
                  `
                : css`
                      background-color: ${lightBlueGray[90]};
                  `}
    }

    &:before {
        top: 0;
    }

    &:after {
        bottom: 0;
    }
`;

const Empty = styled.div`
    font-size: 1.4em;
    text-align: center;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StandRow = (props: DashboardCardProps): React.ReactElement => {
    const { icon, children, gradientBorder, empty } = props;

    return (
        <Root>
            {icon ? <Icon>{icon}</Icon> : null}

            <Content gradientBorder={gradientBorder}>
                <Background gradientBorder={gradientBorder} />
                <Arrow />

                {empty ? <Empty>Нет данных</Empty> : children}
            </Content>
        </Root>
    );
};

export { StandRow };
