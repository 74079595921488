import { useSubscription } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { SdbInformationDataEntity } from '@modules/dashboard/entities';
import { OnSDBInformationDataUpdateSubscription } from '@modules/dashboard/graphql';

import type { OnSdbInformationDataUpdateSubscriptionType } from '@modules/types/graphql';

const useSdbInformationData = () => {
    const subscriptionResult = useSubscription<OnSdbInformationDataUpdateSubscriptionType>(OnSDBInformationDataUpdateSubscription);

    const sdbInformationData = useEntity(
        () => new SdbInformationDataEntity(subscriptionResult.data?.onSDBInformationDataUpdate ?? null),
        [subscriptionResult.data],
    );

    const result = {
        sdbInformationData,
        ...subscriptionResult,
    };

    return result;
};

export { useSdbInformationData };
