import { BaseEntity, Entity as DecribeEntity, RelationList } from '@lib/entity';

import { Gender } from '@modules/types/graphql';
import { SdbInformationBirthdateStudentEntityList } from './sdb-information-birthdate-student-entity-list';
import { SdbEventEntityList } from './sdb-event-entity-list';
import { SdbGenderStudentsEntityList } from './sdb-gender-students-entity-list';

import type { SdbInformationData } from '@modules/types/graphql';
import type { SdbInformationBirthdateStudentEntity } from './sdb-information-birthdate-student-entity';
import type { SdbEventEntity } from './sdb-event-entity';
import type { SdbGenderStudentsEntity } from './sdb-gender-students-entity';

export type Entity = SdbInformationData | null;

export type CompaniesRatingItem = {
    company: number;
    score: number;
};

export type PlatoonsRatingItem = {
    platoonX: number;
    score: number;
};

export type Lap = {
    from: string;
    to: string;
};

@DecribeEntity('SdbInformationDataEntity')
class SdbInformationDataEntity extends BaseEntity {
    students: number;
    companiesRating: (CompaniesRatingItem | null)[];
    platoonsRating: (PlatoonsRatingItem | null)[];
    lap: Lap | null;

    @RelationList(() => SdbInformationBirthdateStudentEntityList)
    birthdateStudents: SdbInformationBirthdateStudentEntity[];

    @RelationList(() => SdbEventEntityList)
    events: SdbEventEntity[];

    @RelationList(() => SdbGenderStudentsEntityList)
    genderStudents: SdbGenderStudentsEntity[];

    constructor(entity: Entity) {
        super(entity);

        this.students = entity?.students ?? 0;
        this.companiesRating = entity?.companiesRating ?? [];
        this.platoonsRating = entity?.platoonsRating ?? [];

        this.lap = entity?.lap
            ? {
                  from: entity.lap.from ?? '',
                  to: entity.lap.to ?? '',
              }
            : null;
    }

    getMaxScoreRating() {
        let maxScore = 0;

        if (this.companiesRating && this.companiesRating.length > 0) {
            const entityWithMaxScore = this.companiesRating.reduce((carry, entity) => {
                if (carry && entity && carry.score > entity.score) {
                    return carry;
                }

                return entity;
            });

            maxScore = entityWithMaxScore?.score ?? 0;
        }

        return maxScore;
    }

    takePlatoonsRating(offset: number, limit: number) {
        return this.platoonsRating?.slice(offset, limit);
    }

    getStudentsCollectionByGender(gender: Gender) {
        const result = this.genderStudents.find(collection => collection.gender === gender);

        return result;
    }

    getGenderStudentsPercent(gender: Gender) {
        const total = this.genderStudents.reduce(
            (carry, collection) => (carry += collection.summary),
            0,
        );

        const studentsCollection = this.getStudentsCollectionByGender(gender);

        if (!studentsCollection || total === 0) {
            return 0;
        }

        const result = Math.round((studentsCollection.summary / total) * 100);

        return result;
    }

    getGreaterStudentsCollection() {
        const male = this.getStudentsCollectionByGender(Gender.male);
        const female = this.getStudentsCollectionByGender(Gender.female);

        const maleCount = male?.items?.length ?? 0;
        const femaleCount = female?.items?.length ?? 0;

        if (maleCount > femaleCount) {
            return maleCount;
        }

        return femaleCount;
    }
}

export { SdbInformationDataEntity };
