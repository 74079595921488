import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

import type { Lap } from '@modules/dashboard/entities';

const getFormattedLap = (lap?: Lap | null): string => {
    if (!lap) {
        return '';
    }

    try {
        const fromDate = new Date(lap.from);
        const toDate = new Date(lap.to);
        const from = format(fromDate, 'dd MMMM', { locale: ru });
        const to = format(toDate, 'dd MMMM', { locale: ru });

        return `${from} - ${to}`;
    } catch {
        return '';
    }
}

export { getFormattedLap }
