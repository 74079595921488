import React from 'react';
import styled, { css } from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { Loader } from '@modules/layout/moleculas';
import {
    useSdbInformationData,
    useSdbInformationDate,
    useSdbInformationWeather,
    useTopStudentsRatings,
} from '@modules/dashboard/hooks';
import {
    DashboardEntry,
    PlatoonRatingsEntry,
    WeatherEntry,
    StudentsBirthdayEntry,
    EventsEntry,
    GenderStudentsEntry,
} from '@modules/dashboard/entries';

const Root = styled.div<{ withAnimation?: boolean }>`
    width: 100%;
    height: 100%;
    background: black;

    ${p =>
        p.withAnimation &&
        css`
            > * {
                position: absolute;
                top: 0;
                left: 0;
                transform: translateX(-100%);
                transition: all 1000ms ease-in;
            }

            .fade-enter-active,
            .fade-enter-done {
                transform: translateX(0);
            }

            .fade-exit-active,
            .fade-exit-done {
                transform: translateX(-100%);
            }
        `}
`;

const DEFAULT_SCREENS = [
    {
        key: 'dashboard',
        timeout: 15000,
        Component: DashboardEntry,
    },
    {
        key: 'platoonRatings',
        timeout: 15000,
        Component: PlatoonRatingsEntry,
    },
    {
        key: 'weather',
        timeout: 15000,
        Component: WeatherEntry,
    },
    {
        key: 'genderStudents',
        timeout: 15000,
        Component: GenderStudentsEntry,
    },
];

const studentsBirthdayScreen = {
    key: 'studentsBirthday',
    timeout: 10000,
    Component: StudentsBirthdayEntry,
};

const eventsScreen = {
    key: 'events',
    timeout: 15000,
    Component: EventsEntry,
};

const DashboardPage = () => {
    const { sdbInformationData, loading: sdbInformationDataLoading } = useSdbInformationData();
    const { sdbInformationDate, loading: sdbInformationDateLoading } = useSdbInformationDate();

    const {
        sdbInformationWeather,
        loading: sdbInformationWeatherLoading,
    } = useSdbInformationWeather();

    const { topStudentsRatings, loading: topStudentsRatingsLoading } = useTopStudentsRatings();

    const [screens, setScreens] = React.useState(() => DEFAULT_SCREENS);
    const [currentScreenKey, setCurrentScreenKey] = React.useState('');
    const [currentTimeout, setCurrentTimeout] = React.useState(0);

    const isLoading =
        sdbInformationDataLoading ||
        sdbInformationDateLoading ||
        sdbInformationWeatherLoading ||
        topStudentsRatingsLoading;

    React.useEffect(() => {
        if (sdbInformationData.birthdateStudents.length > 0) {
            setScreens(prevScreens => {
                const weatherScreenIdx = prevScreens.findIndex(screen => screen.key === 'weather');

                const studentsBirthdayScreenIdx = prevScreens.findIndex(
                    screen => screen.key === 'studentsBirthday',
                );

                if (studentsBirthdayScreenIdx > -1) {
                    return prevScreens;
                }

                return [
                    ...prevScreens.slice(0, weatherScreenIdx + 1),
                    studentsBirthdayScreen,
                    ...prevScreens.slice(weatherScreenIdx + 1),
                ];
            });
        }
    }, [sdbInformationData.birthdateStudents.length]);

    React.useEffect(() => {
        if (sdbInformationData.events.length > 0) {
            setScreens(prevScreens => {
                const weatherScreenIdx = prevScreens.findIndex(screen => screen.key === 'weather');

                const studentsBirthdayScreenIdx = prevScreens.findIndex(
                    screen => screen.key === 'studentsBirthday',
                );

                const eventsScreenIdx = prevScreens.findIndex(screen => screen.key === 'events');

                if (eventsScreenIdx > -1) {
                    return prevScreens;
                }

                if (studentsBirthdayScreenIdx === -1) {
                    return [
                        ...prevScreens.slice(0, weatherScreenIdx + 1),
                        eventsScreen,
                        ...prevScreens.slice(weatherScreenIdx + 1),
                    ];
                }

                return [
                    ...prevScreens.slice(0, studentsBirthdayScreenIdx + 1),
                    eventsScreen,
                    ...prevScreens.slice(studentsBirthdayScreenIdx + 1),
                ];
            });
        }
    }, [sdbInformationData.events.length]);

    React.useEffect(() => {
        if (!isLoading && screens.length !== 0) {
            setCurrentScreenKey(screens[0].key);
            setCurrentTimeout(screens[0].timeout);
        }
    }, [screens, isLoading]);

    React.useEffect(() => {
        let timeoutId: NodeJS.Timeout | null = null;

        const currentScreenIdx = screens.findIndex(screen => screen.key === currentScreenKey);
        const currentScreen = screens[currentScreenIdx];

        if (!currentScreen) {
            return;
        }

        timeoutId = setTimeout(() => {
            let nextScreenIdx = currentScreenIdx + 1;

            if (nextScreenIdx >= screens.length) {
                nextScreenIdx = 0;
            }

            const nextScreen = screens[nextScreenIdx];

            setCurrentScreenKey(nextScreen.key);
            setCurrentTimeout(nextScreen.timeout + additionalTimeoutRef.current);

            additionalTimeoutRef.current = 0;
        }, currentTimeout);

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
                timeoutId = null;
            }
        };
    }, [screens, currentScreenKey, currentTimeout]);

    const additionalTimeoutRef = React.useRef(0);

    const handleAddToCurrentTimeout = (additionalTimeout: number) => {
        additionalTimeoutRef.current = additionalTimeout;
    };

    if (isLoading || screens.length === 0) {
        return (
            <Root>
                <Loader size={60} />
            </Root>
        );
    }

    return (
        <Root withAnimation>
            {screens.map(screen => (
                <CSSTransition
                    unmountOnExit
                    classNames='fade'
                    timeout={1000}
                    key={screen.key}
                    in={currentScreenKey === screen.key}
                >
                    <screen.Component
                        informationData={sdbInformationData}
                        informationDate={sdbInformationDate}
                        informationWeather={sdbInformationWeather}
                        topStudentsRatings={topStudentsRatings}
                        onAddTransitionTimeout={handleAddToCurrentTimeout}
                    />
                </CSSTransition>
            ))}
        </Root>
    );
};

export { DashboardPage };
