import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';

import { BaseEntity, Entity as DecribeEntity, Relation } from '@lib/entity';
import { FileInfoEntity } from '@modules/shared/entities';
import { getDayOfWeek } from '@modules/dashboard/helpers';

import type { Event } from '@modules/types/graphql';

export type Entity = Partial<Event> | null;

@DecribeEntity('SdbEventEntity')
class SdbEventEntity extends BaseEntity {
    id: string;
    name: string;
    placeName: string;
    date: string;
    time: string;
    photoId: string;

    @Relation(() => FileInfoEntity)
    photo: FileInfoEntity;

    constructor(event: Entity) {
        super(event);

        this.id = event?.id ?? '';
        this.name = event?.name ?? '';
        this.placeName = event?.placeName ?? '';
        this.date = event?.date ?? '';
        this.time = event?.time ?? '';
    }

    exists() {
        const exists = this.id !== '';

        return exists;
    }

    getRawDate(dateString: string): Date | null {
        if (!dateString) {
            return null;
        }

        const date = new Date(dateString);

        return date;
    }

    getDate() {
        const date = this.date ? new Date(this.date) : null;

        const result = date ? format(date, 'dd.MM.yyyy', { locale: ru }) : '//';

        return result;
    }

    getDay() {
        if (!this.date) {
            return '';
        }

        try {
            const currentDate = new Date(this.date);
            const month = format(currentDate, 'dd', { locale: ru });

            return month;
        } catch {
            return '';
        }
    }

    getMonth() {
        if (!this.date) {
            return '';
        }

        try {
            const currentDate = new Date(this.date);
            const month = format(currentDate, 'MMMM', { locale: ru });

            return month;
        } catch {
            return '';
        }
    }

    getDayOfWeek() {
        if (!this.date) {
            return '';
        }

        try {
            const currentDate = new Date(this.date);
            const dayOfWeek = format(currentDate, 'EEEE', { locale: ru });

            return dayOfWeek;
        } catch {
            return '';
        }
    }
}

export { SdbEventEntity };
