import { BaseEntity, Entity as DecribeEntity, Relation, RelationList } from '@lib/entity';

import { SdbInformationWeatherEntity } from './sdb-information-weather-entity';
import { SdbSubscriptionInformationWeatherForecastEntityList } from './sdb-subscription-information-weather-forecast-entity-list';
import { SdbSubscriptionInformationWeatherForecastEntity } from './sdb-subscription-information-weather-forecast-entity';

import type { SdbSubscriptionInformationWeather } from '@modules/types/graphql';

export type Entity = SdbSubscriptionInformationWeather | null;

@DecribeEntity('SdbSubscriptionInformationWeatherEntity')
class SdbSubscriptionInformationWeatherEntity extends BaseEntity {
    @Relation(() => SdbInformationWeatherEntity)
    fact: SdbInformationWeatherEntity;

    @RelationList(() => SdbSubscriptionInformationWeatherForecastEntityList)
    forecasts: SdbSubscriptionInformationWeatherForecastEntity[];

    getTodayForecast() {
        return this.forecasts[0] ?? new SdbSubscriptionInformationWeatherForecastEntity(null);
    }

    getTomorrowForecast() {
        return this.forecasts[1] ?? new SdbSubscriptionInformationWeatherForecastEntity(null);
    }
}

export { SdbSubscriptionInformationWeatherEntity };
