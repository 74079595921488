import React from 'react';
import styled from 'styled-components';

import { StandCard } from '@modules/shared/organisms';
import { StandPageTitle } from '@modules/layout/moleculas';
import { DashboardWeatherDetails } from '@modules/dashboard/organisms';

import type { EntryProps } from './types';

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    padding: 30px;
    overflow: hidden;
    background: black;
`;

const Grids = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    > * {
        width: calc(50% - 32px);
    }
`;

const WeatherEntry = (props: EntryProps): React.ReactElement => {
    const { informationData, informationDate, informationWeather } = props;

    return (
        <Root>
            <StandPageTitle
                title='Погода'
                studentsCount={informationData.students}
                currentDate={informationDate}
            />

            <Grids>
                <StandCard>
                    <DashboardWeatherDetails
                        variant='today'
                        currentDate={informationDate}
                        forecast={informationWeather.getTodayForecast()}
                    />
                </StandCard>

                <StandCard>
                    <DashboardWeatherDetails
                        variant='tomorrow'
                        currentDate={informationDate}
                        forecast={informationWeather.getTomorrowForecast()}
                    />
                </StandCard>
            </Grids>
        </Root>
    );
};

export { WeatherEntry };
