import React from 'react';
import styled from 'styled-components';

type GenderStudentsListItemProps = {
    age: number;
    count: number;
    maxCount: number;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    font-size: 3.8rem;
`;

const Label = styled.div`
    width: 150px;
    color: #fff;
`;

const Lines = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-left: 16px;
`;

const Line = styled.div`
    height: 36px;
    width: 18px;
    background-color: currentColor;
    transform: skew(-15deg, 0deg);
`;

const Count = styled.div`
    margin-left: auto;
`;

const getAgeLabel = (age: number) => {
    const tenthNumber = Math.floor(age / 10);
    const restNumber = age % 10;

    if (tenthNumber > 1) {
        if (restNumber === 1) {
            return age + ' год';
        }

        if (restNumber > 1 && restNumber < 5) {
            return age + ' года';
        }
    }

    return age + ' лет';
};

const GenderStudentsListItem = (props: GenderStudentsListItemProps) => {
    const { age, count, maxCount } = props;

    const label = getAgeLabel(age);

    const maxLinesCount = maxCount > 999 ? 9 : 10;
    const ageRatioToMaxCount = maxCount > 0 ? count / maxCount : 0;

    const linesCount = Math.ceil(ageRatioToMaxCount * maxLinesCount);

    const lines = React.useMemo(
        () => Array.from({ length: linesCount }).map((_, idx) => <Line key={idx} />),
        [linesCount],
    );

    return (
        <Root>
            <Label>{label}</Label>
            <Lines>{lines}</Lines>
            <Count>{count}</Count>
        </Root>
    );
};

export { GenderStudentsListItem };
