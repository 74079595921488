import styled from 'styled-components';
import { red } from '@avangard/ui/colors';

import { CommonHelper } from '@helpers';
import { lightBlueGray } from '@modules/ui/core/colors';

import type { SdbInformationBirthdateStudentEntity } from '@modules/dashboard/entities';

type DashboardStudentBirthdayDetailsProps = {
    birthdateStudent: SdbInformationBirthdateStudentEntity;
};

const Root = styled.div`
    width: 100%;
    text-align: center;
`;

const Title = styled.div`
    color: ${lightBlueGray[50]};
    font-size: 8rem;
    line-height: 100px;
    text-transform: uppercase;
`;

const Main = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 90px;

    > h4 {
        color: ${lightBlueGray[50]};
        font-size: 5.6rem;
        line-height: 60px;
    }

    > * {
        width: 100%;
        text-align: center;
    }
`;

const Name = styled.div`
    height: 180px;
    color: ${lightBlueGray[50]};
    font-size: 6rem;
    line-height: 60px;
    text-transform: uppercase;
`;

const Info = styled.div`
    margin-top: 56px;
    color: ${lightBlueGray[50]};
    font-size: 3.2rem;
    line-height: 38px;
    text-transform: uppercase;
`;

const Age = styled.div`
    margin-top: 120px;
    color: ${red[100]};
    font-size: 8rem;
    line-height: 100px;
    text-transform: uppercase;
`;

const DashboardStudentBirthdayDetails = (props: DashboardStudentBirthdayDetailsProps) => {
    const { birthdateStudent } = props;

    const displayAge = CommonHelper.createCountFormatter(birthdateStudent.age, [
        'год',
        'года',
        'лет',
    ]);

    return (
        <Root>
            <Title>{birthdateStudent.getFormattedBirthdate()}</Title>

            <Main>
                <Name>{birthdateStudent.fullName}</Name>
                <Info>
                    {birthdateStudent.company} рота, {birthdateStudent.platoon} взвод
                </Info>
                <Age>{displayAge}</Age>
            </Main>
        </Root>
    );
};

export { DashboardStudentBirthdayDetails };
