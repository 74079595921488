import { gql } from '@apollo/client';

import { FileInfoFragment } from '@modules/shared/graphql';

export const OnSDBInformationDataUpdateSubscription = gql`
    subscription OnSDBInformationDataUpdate {
        onSDBInformationDataUpdate {
            students
            birthdateStudents {
                id
                fullName
                company
                platoon
                birthdate
                age
            }
            events {
                id
                name
                placeName
                date
                time
                photo {
                    ...FileInfo
                }
            }
            companiesRating {
                company
                score
            }
            platoonsRating {
                platoonX
                score
            }
            genderStudents {
                gender
                summary
                items {
                    gender
                    age
                    count
                }
            }
            lap {
                from
                to
            }
        }
    }
    ${FileInfoFragment}
`;
