import styled, { css } from 'styled-components';

import { lightBlueGray, red } from '@modules/ui/core/colors';

type RectangleProps = {
    variant: 'primary' | 'secondary';
    percent: number;
};

const MAX_HEIGHT = 420;

const Root = styled.div<Pick<RectangleProps, 'variant'> & { height?: number }>`
    position: relative;
    width: 300px;
    height: ${p => p.height ?? 0}px;
    margin: 0 auto;
    transform: rotateX(-40deg) rotateY(45deg);
    transform-style: preserve-3d;
    z-index: 10;

    ${p =>
        (p.variant === 'primary' &&
            css`
                > * {
                    background-color: ${lightBlueGray[50]};
                }
            `) ||
        (p.variant === 'secondary' &&
            css`
                > * {
                    background-color: ${red[50]};
                }
            `)}
`;

const Side = styled.div<{ plane: 'top' | 'right' | 'left'; height?: number }>`
    position: absolute;
    width: 100%;
    border: 1px solid black;

    ${p =>
        (p.plane === 'top' &&
            css`
                height: 300px;
                transform: rotateX(90deg) translateZ(150px);
            `) ||
        (p.plane === 'right' &&
            css`
                height: ${p.height ?? 0}px;
                transform: rotateY(0) translateZ(150px);
            `) ||
        (p.plane === 'left' &&
            css`
                height: ${p.height ?? 0}px;
                transform: rotateY(-90deg) translateZ(150px);
            `)}
`;

const Rectangle = (props: RectangleProps) => {
    const { variant, percent } = props;

    const sideHeight = (percent / 100) * MAX_HEIGHT;

    return (
        <Root variant={variant} height={sideHeight}>
            <Side plane='top' />
            <Side plane='right' height={sideHeight} />
            <Side plane='left' height={sideHeight} />
        </Root>
    );
};

export { Rectangle };
