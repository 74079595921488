import { EntityList } from '@lib/entity';
import { SdbGenderStudentEntity } from './sdb-gender-student-entity';

import type { Entity } from './sdb-event-entity';

class SdbGenderStudentEntityList extends EntityList<SdbGenderStudentEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(SdbGenderStudentEntity, items, count);
    }
}

export { SdbGenderStudentEntityList };
