import { EntityList } from '@lib/entity';
import { SdbEventEntity } from './sdb-event-entity';

import type { Entity } from './sdb-event-entity';

class SdbEventEntityList extends EntityList<SdbEventEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(SdbEventEntity, items, count);
    }
}

export { SdbEventEntityList };
