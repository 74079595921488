import { EntityList } from '@lib/entity';
import { SdbGenderStudentsEntity } from './sdb-gender-students-entity';

import type { Entity } from './sdb-event-entity';

class SdbGenderStudentsEntityList extends EntityList<SdbGenderStudentsEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(SdbGenderStudentsEntity, items, count);
    }
}

export { SdbGenderStudentsEntityList };
