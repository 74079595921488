import React from 'react';
import styled from 'styled-components';

import { StandCard, StandRow } from '@modules/shared/organisms';
import { WeatherIcon } from '@modules/dashboard/atoms';
import {
    DashboardTop,
    CompaniesRating,
    Schedule,
    Weather,
    DashboardBestStudents,
} from '@modules/dashboard/organisms';

import type { EntryProps } from './types';

const Root = styled.div`
    display: flex;
    width: 100%;
    min-height: 100%;
    padding: 30px;
    overflow: hidden;
    background: black;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * {
        width: 100%;

        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
`;

const Left = styled(Column)`
    width: 50%;
    padding-right: 40px;
`;

const Right = styled(Column)`
    width: 50%;
`;

const DashboardEntry = (props: EntryProps): React.ReactElement => {
    const { informationData, informationDate, informationWeather, topStudentsRatings } = props;

    const maxScoreRating = informationData.getMaxScoreRating();

    return (
        <Root>
            <Left>
                <DashboardTop
                    students={informationData.students}
                    date={informationDate}
                    lap={informationData.lap}
                />

                <StandRow
                    gradientBorder
                    empty={!informationWeather}
                    icon={<WeatherIcon slug={informationWeather.fact.conditionSlug} />}
                >
                    <Weather
                        temp={informationWeather.fact.temp}
                        condition={informationWeather.fact.condition}
                    />
                </StandRow>

                <DashboardBestStudents students={topStudentsRatings} />
            </Left>

            <Right>
                <StandCard>
                    <CompaniesRating
                        companies={informationData?.companiesRating}
                        maxScore={maxScoreRating}
                    />
                </StandCard>

                <StandCard>
                    <Schedule />
                </StandCard>
            </Right>
        </Root>
    );
};

export { DashboardEntry };
