import { gql } from '@apollo/client';

export const OnSDBInformationWeatherUpdateSubscription = gql`
    subscription OnSDBInformationWeatherUpdate {
        onSDBInformationWeatherUpdate {
            fact {
                temp
                icon
                conditionSlug
                condition
            }
            forecasts {
                date
                day {
                    temp
                    icon
                    conditionSlug
                    condition
                }
                night {
                    temp
                    icon
                    conditionSlug
                    condition
                }
            }
        }
    }
`;
